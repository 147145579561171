<template>
  <q-card flat class="text-center">
    <q-card-section class="q-pa-md">
      <div class="text-h6">{{ header }}</div>
    </q-card-section>
    <q-card-section class="q-pt-none">
      {{ body }}
    </q-card-section>
  </q-card>
</template>

<script>
import { computed, toRef } from "vue";

export default {
  props: {
    messageType: String,
  },
  setup(props) {
    let messages = {
      invalid: {
        header:
          "Dit aanbod kan niet bevestigd worden, er is een nieuw aanbod aan u verzonden.",
        body: "Controleer s.v.p. uw e-mail voor het meest recente aanbod om dit te bevestigen.",
      },
    };
    const messageType = toRef(props, "messageType");
    const header = computed(() => {
      return messages[messageType.value]?.header ?? "Error";
    });
    const body = computed(() => {
      return (
        messages[messageType.value]?.body ?? "An unexpected error has occured."
      );
    });
    return {
      header,
      body,
    };
  },
};
</script>
